export default class MasterCSVExportService {
  constructor(axios) {
    this.axios = axios
  }

  async getInit() {
    return await this.axios.$get('/csv/init');
  }
  async getData(id) {
    return await this.axios.$get('/csv/download', { params: { code: id } });
  }
  async addFileUploadWastes(param) {
    return await this.axios.$post('/csv/import/wastes', param);
  }
  async addFileUploadProduct(param) {
    return await this.axios.$post('/csv/import/products', param);
  }
  async addFileUploadProcurements(param) {
    return await this.axios.$post('/csv/import/procurements', param);
  }
}