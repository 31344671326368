import moment from "moment"

const MONTH_FORMAT = "YYYY-MM"
const DATE_FORMAT = MONTH_FORMAT + "-DD"

/** 空白チェック */
const isEmpty = function (value) {
  if (value == null || value === "" || value.length === 0) {
    return true;
  }
  return false;
}

/** 半角に変換する */
const convertToHalfWidth = function (value) {
  return String(value).replace(/[\uff01-\uff5e]/g, function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); });
}

/**
 * 日付フォーマットの検証を行う
 * @param format
 */
const date_format = {
  params: [],

  validate(value) {
    return moment(value, DATE_FORMAT).isValid()
  },

  message(field) {
    return `${field}は${DATE_FORMAT}の形式で入力してください。`
  },
}

/**
 * 年月フォーマットの検証を行う
 * @param format
 */
const month_format = {
  params: [],

  validate(value) {
    return moment(value, MONTH_FORMAT).isValid()
  },

  message(field) {
    return `${field}は${MONTH_FORMAT}の形式で入力してください。`
  },
}

/**
 * 開始年月＞システム日付の年月の検証を行う
 * @param format
 */
const from_current_month = {
  params: [],
  validate(value) {
    if (!value) return false;
    let objDate = new Date()
    let current = objDate.getFullYear().toString() + ("0" + (objDate.getMonth() + 1)).slice(-2);
    return parseInt(current) >= parseInt(value.replace("-", ""));
  },

  message(field) {
    return `${field}は現在の年月、またはそれ以前の年月を設定してください。`
  },
}

/**
 * 日付が指定した範囲内かどうかの検証を行う
 * @params from,to
 */
const date_between = {
  params: ["from", "to"],

  validate(value, { from, to }) {
    if (!from) from = value
    if (!to) to = value

    return moment(value).isBetween(from, to)
  },
  message(field, { from, to }) {
    if (from && to) {
      return `${field}は${from}~${to}の範囲で入力してください。`
    } else if (from) {
      return `${field}は${from}以降で入力してください。`
    } else if (to) {
      return `${field}は${to}以前で入力してください。`
    }
    return `日付範囲が不正です。`
  }
}
/**
* 日付が指定した日にちのminかどうかの検証を行う
* @params from
*/
const min_month = {
  params: ["from"],

  validate(value, { from }) {
    if (!from) return true;
    return moment(value).isSameOrAfter(from)
  },

  message(field, { from }) {

    return `${field}は${from}以降で入力してください。`
  },
}

/**
* 日付が指定した日にちのmaxかどうかの検証を行う
* @params to
*/
const max_month = {
  params: ["to"],

  validate(value, { to }) {
    if (!to) return true;
    return moment(value).isBefore(to)
  },

  message(field, { to }) {
    return `${field}は${to}以前で入力してください。`
  },
}

/**
 * 日付前後関係の検証を行う
 * @params valueIsFrom,acceptEquals
 */
const date_order = {
  params: [
    { name: "compareVal", isTarget: true },
    { name: "valueIsFrom" },
    { name: "acceptEquals" },
  ],

  validate(value, { compareVal, valueIsFrom = true, acceptEquals = true }) {
    // boolが文字列で渡されてくるのでその対策
    valueIsFrom = valueIsFrom === "false" || !valueIsFrom ? false : true
    acceptEquals = acceptEquals === "false" || !acceptEquals ? false : true

    if (
      !value ||
      !moment(value, DATE_FORMAT).isValid() ||
      !compareVal ||
      !moment(compareVal, DATE_FORMAT).isValid()
    ) {
      return true
    }

    if (acceptEquals && moment(value).isSame(compareVal)) {
      return true
    } else if (valueIsFrom === true) {
      return moment(value).isBefore(compareVal)
    } else {
      return moment(value).isAfter(compareVal)
    }
  },

  message(field, { from, to }) {
    return `日付の前後関係が不正です。`
  },
}

/**
 * 月前後関係の検証を行う
 * @params valueIsFrom,acceptEquals
 */
const month_order = {
  params: [
    { name: "compareVal", isTarget: true },
    { name: "valueIsFrom" },
    { name: "acceptEquals" },
  ],

  validate(value, { compareVal, valueIsFrom = true, acceptEquals = true }) {
    // boolが文字列で渡されてくるのでその対策
    valueIsFrom = valueIsFrom === "false" || !valueIsFrom ? false : true
    acceptEquals = acceptEquals === "false" || !acceptEquals ? false : true

    if (
      !value ||
      !moment(value, MONTH_FORMAT).isValid() ||
      !compareVal ||
      !moment(compareVal, MONTH_FORMAT).isValid()
    ) {
      return true
    }

    if (acceptEquals && moment(value).isSame(compareVal)) {
      return true
    } else if (valueIsFrom === true) {
      return moment(value).isBefore(compareVal)
    } else {
      return moment(value).isAfter(compareVal)
    }
  },

  message(field, { from, to }) {
    return `対象年月の前後関係が不正です。`
  },
}

/**
 * 時刻フォーマットの検証を行う
 * @param None
 */
const time_format = {
  params: [],

  validate(value) {
    return value.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null
  },

  message(field) {
    return `${field}は00:00の形式で入力してください。`
  },
}

/**
 * 数字が指定した桁数以下か検証する
 * @param max 最大桁数
 */
const max_digits = {
  params: ["max"],

  validate(value, { max }) {
    return value ? String(value).length <= max : true
  },

  message(field, { max }) {
    return `${field}は${max}文字以下でなければなりません。`
  },
}

/**
 * コレクションの要素数が指定していた数以下かを検証する
 * @param maxLength 最大要素数
 */
const collection_max_length = {
  params: ["maxLength"],

  validate(value, { maxLength }) {
    if (!Array.isArray(value)) {
      return false
    }

    return value.length <= maxLength
  },

  message(field, { maxLength }) {
    return `${field}は${maxLength}つまでしか選択することができません。`
  },
}

/**
 * 電話番号フォーマットの検証を行う
 * @param なし
 */
const tel_format = {
  params: ["charCheckOnly"],

  validate(value, { charCheckOnly = false }) {
    const _str = value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, "")
    if (charCheckOnly) {
      return _str.replace(/^[0-9]+$/, "") === ""
    } else {
      return _str.match(/^(0[1-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/) !== null
    }
  },

  message(field) {
    return `${field}を正しく入力してください。`
  },
}

/**
 * 郵便番号フォーマットの検証を行う
 * @param なし
 */
const zipcode_format = {
  params: [],

  validate(value) {
    return value.match(/^[0-9]{3}-[0-9]{4}$/) !== null
  },

  message(field) {
    return `${field}は000-0000の形式で入力してください。`
  },
}

/**
 * 大文字含むか
 * @param なし
 */
const uppercase = {
  params: [],

  validate(value) {
    return value.match(/[A-Z]/)
  },

  message(field) {
    return `${field}は大文字を1文字以上含んでください`
  },
}

/**
 * 小文字含むか
 * @param なし
 */
const lowercase = {
  params: [],

  validate(value) {
    return value.match(/[a-z]/)
  },

  message(field) {
    return `${field}は小文字を1文字以上含んでください`
  },
}

/**
 * 数字含むか
 * @param なし
 */
const number = {
  params: [],

  validate(value) {
    return value?.toString().match(/[0-9]/)
  },

  message(field) {
    return `${field}は数字を1文字以上含んでください`
  },
}

/**
 * 記号含むか
 * @param なし
 */
const special_char = {
  params: [],

  validate(value) {
    return value.match(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g)
  },
  message(field) {
    return `${field}は記号を1文字以上含んでください`
  },
}

/**
 * リスト内に既に含まれているか
 * @param list 検査対象リスト
 */
const exis_item = {
  params: ["myIndex", "label", "list"],

  validate(value, { myIndex, label, list }) {
    let targetlist = list

    // 要素数が1の配列は0番目の要素が展開されて入ってしまうようなのでここでリストに戻す
    if (!Array.isArray(list)) {
      targetlist = [list]
    }

    if (myIndex != null && myIndex != "") {
      targetlist = list.filter((_, listIndex) => {
        return myIndex != listIndex
      })
    }

    targetlist.some((item) => {
      item == value
    })
    if (label != null && label != "") {
      return !targetlist.some((item) => item[label] == value)
    } else {
      return !targetlist.some((item) => item == value)
    }
  },
  message(field) {
    return `既に追加されています`
  },
}

/**
 * コンマが付けて数字をチェック
 * 
 * @param positive_flag 正数フラグ
 */
const number_comma = {
  params: ["positive_flag"],
  validate(value, { positive_flag = false }) {
    if (isEmpty(value)) {
      return true;
    }
    let isPositive = positive_flag === 'true' || positive_flag === true;
    const pattern = isPositive ? /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/ : /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/;
    return String(value).match(pattern);
  },

  message(field, { positive_flag = false }) {
    if (positive_flag) {
      return `${field}は正数を入力してください`;
    }
    return `${field}は数字を入力してください`;
  },
}

const number_comma_full_width = {
  params: ["positive_flag"],
  validate(value, { positive_flag = false }) {
    if (isEmpty(value)) {
      return true;
    }
    let isPositive = positive_flag === 'true' || positive_flag === true;
    var halfWidth = convertToHalfWidth(value);
    const pattern = isPositive ? /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/ : /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/;
    return !!String(halfWidth).match(pattern);
  },

  message(field, { positive_flag = false }) {
    if (positive_flag) {
      return `${field}は正数を入力してください`;
    }
    return `${field}は数字を入力してください`;
  },
}

const numeric_right = {
  params: ['scale'],
  validate(value, { scale }) {
    if (isEmpty(value)) {
      return true
    }
    let halfWidth = convertToHalfWidth(value)
    halfWidth = halfWidth.replaceAll(',', '')
    const right = halfWidth.split('.')[1]
    if (right)
      return right.length <= scale
    return true
  }
}

/**
 * コンマが付けて数字をチェック
 * 
 * @param positive_flag 正数フラグ
 */
const length_comma = {
  params: ["max"],

  validate(value, { max }) {
    var halfWidth = convertToHalfWidth(value)
    if (halfWidth.includes("e+")) return false
    var str = String(halfWidth).replace(/[^\d\.\-]/g, "");
    let isLessThanMax = true
    try {
      if (isNaN(str)) {
        return str.split(".")[0].length <= max
      }
      const maxNumber = Math.pow(10, max)
      isLessThanMax = Number(str) < maxNumber
    } catch (err) {
      console.error(err)
    }
    return str.split(".")[0].length <= max && isLessThanMax
  },
}

const length_decimal = {
  params: ["max"],
  validate(value, { max }) {
    var halfWidth = convertToHalfWidth(value)
    var str = String(halfWidth).replace(/[^\d\.\-]/g, "");
    if (str.includes(".")) {
      return str.split(".")[1].length <= max
    }
    return true
  },
}

const integer_only = {
  validate(value) {
    var halfWidth = convertToHalfWidth(value)
    var str = String(halfWidth).replace(/[^\d\.\-]/g, "");
    if (str.includes(".")) {
      return false
    }
    return true
  },
}


const number_full_width = {
  params: [],

  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    return String(halfWidth).match(/^(\d+|\d{1,3}(,\d{3})*)$/);
  },
}

const not_zero = {
  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    let halfWidth = convertToHalfWidth(value);
    if (halfWidth.includes('/')) return true
    if (Number(halfWidth.replaceAll(',', '')) === 0) return false
    return true
  },
  message() {
    return `ゼロは入力できません。`;
  }
}

const fraction = {
  params: [],

  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    if (halfWidth.includes('/'))
      return String(halfWidth).match(/^(\d+)\/(\d+)$/)
    return isNumberComma(halfWidth)
  },
  message(field) {
    return `${field}は数値または分数を入力してください。`;
  }
}

const fraction_numerator = {
  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    if (!halfWidth.includes('/')) return true
    const numerator = halfWidth.split('/')[1].replaceAll(',', '')
    if (Number(numerator) === 0) return false
    return true
  },
  message() {
    return `分母にゼロは入力できません。`;
  }
}

const fraction_determinator = {
  params: [],

  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    if (!halfWidth.includes('/')) return true
    const determinator = halfWidth.split('/')[0].replaceAll(',', '')
    if (Number(determinator) === 0) return false
    return true
  },
  message() {
    return `分子にゼロは入力できません。`;
  }
}

const fraction_value = {
  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    if (halfWidth.includes('/')) return true

    return Number(String(halfWidth).replaceAll(',', '')) <= 1
  },
  message() {
    return `１より大きい値は入力できません。`;
  }
}

const fraction_value_2 = {
  validate(value) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value);
    if (!halfWidth.includes('/')) return true
    const arr = halfWidth.split('/')
    const determinator = arr[0].replaceAll(',', '')
    const numerator = arr[1].replaceAll(',', '')
    return Number(determinator) <= Number(numerator)
  },
  message() {
    return `１より大きい値は入力できません。`;
  }
}

const isNumberComma = (value) => {

  const halfWidth = convertToHalfWidth(value);
  const pattern = /(^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$)/
  return String(halfWidth).match(pattern);
}

function round(val, precision) {
  var step = Math.pow(10, precision);
  var num = Math.round(toNumber(val) * step) / step;
  return num;
}

function toNumber(val) {
  if (val == null || val == "") {
    return 0;
  }

  // コンマを取り消し
  if (typeof val == "string") {
    val = convertToHalfWidth(val).replace(/[^\d\.\-]/g, "");
  }

  let num = Number(val);
  if (isNaN(num)) {
    return val;
  }
  return num;
}

const number_max = {
  params: ["max"],

  validate(value, { max }) {
    if (isEmpty(value)) {
      return true;
    }
    var halfWidth = convertToHalfWidth(value).replaceAll(',', '');
    return String(halfWidth).match(/^[0-9]*$/) && String(halfWidth).length <= max
  },

  message(field, { max }) {
    return `${field}は${max}桁以内の正数を入力してください。`
  },
}

const number_length = {
  params: ["length"],

  validate(value, { length }) {
    if (isEmpty(value)) {
      return true;
    }
    const halfWidth = convertToHalfWidth(value).replaceAll(',', '');
    return String(halfWidth).match(/^[0-9]*$/) && String(halfWidth).length === Number(length)
  },

  message(field, { length }) {
    return `${field}は${length}桁の数値を入力してください。`
  },
}

const column_unique = {
  params: ['filteredRows', 'key', 'item'],
  validate(value, { filteredRows, key, item }) {
    // [
    //   'electricOperator',
    //   'retailElectricCalculationMethod',
    //   'supplyMenuCode',
    // ]
    const keys = Object.keys(key)
    let check = true
    const ids = []
    let itemId
    if (typeof key === 'object') {
      for (let i of filteredRows) {
        const id = keys.map((k) => i[k]).join('-')
        if (id.startsWith('-') || id.endsWith('-')) continue
        ids.push(id)
      }
      if (item) {
        itemId = keys.map((k) => item[k]).join('-')
      }
    } else {
      for (let i of filteredRows) {
        const id = i[key]
        if (!id) continue
        ids.push(id)
      }

      itemId = value
    }
    let count = 0
    for (let id of ids) {
      if (id === itemId) {
        count++
      }
      if (count === 2) {
        check = false
        break
      }
    }

    return check
  }
}

const custom_min_value = {
  params: ['min'],
  validate(value, { min }) {
    if (isEmpty(value)) return true
    return toNumber(value) > min
  }
}

const custom_max_value = {
  params: ['max'],
  validate(value, { max }) {
    if (isEmpty(value)) return true
    return toNumber(value) <= max
  }
}

const kana = {
  validate(value) {
    return value.match(/^[ァ-ヶー　 ]+$/)
  }
}

const password = {
  validate(value) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}/.test(value)
  }
}

const hankaku_num = {
  validate(value) {
    return /^[A-Za-z0-9]+$/.test(value)
  }
}

const time_valid = {
  params: ['time', 'type'],
  validate(value, { time, type }) {
    if (time === "") {
      return true
    }
    let sDate
    let eDate
    if (type === "start") {
      sDate = Date.parse(value)
      eDate = Date.parse(time)
    } else {
      sDate = Date.parse(time)
      eDate = Date.parse(value)
    }
    if (isNaN(eDate) || isNaN(sDate)) {
      return false
    }
    if (eDate < sDate) {
      return false
    }
    return true
  }
}

const news_base_org_valid = {
  params: ['baseValue', 'orgValue'],
  validate(value, { baseValue, orgValue }) {
    if (Number(baseValue) === -1 && Number(baseValue) == Number(orgValue)) {
      return false
    }
    return true
  }
}

// allow zenkaku number
const hankaku_num_2 = {
  validate(value) {
    return /^[A-Za-z0-9]+$/.test(convertToHalfWidth(value))
  }
}

// allow zenkaku number and kanji
const alpha_num_2 = {
  validate(value) {
    return /[A-Za-z0-9_]/.test(convertToHalfWidth(value))
  }
}

export {
  date_format,
  month_format,
  date_between,
  date_order,
  month_order,
  time_format,
  max_digits,
  collection_max_length,
  tel_format,
  zipcode_format,
  uppercase,
  lowercase,
  number,
  number_full_width,
  number_comma,
  number_comma_full_width,
  length_comma,
  length_decimal,
  integer_only,
  special_char,
  exis_item,
  from_current_month,
  min_month,
  max_month,
  fraction,
  fraction_numerator,
  not_zero,
  fraction_determinator,
  fraction_value,
  fraction_value_2,
  number_max,
  numeric_right,
  column_unique,
  custom_min_value,
  custom_max_value,
  number_length,
  kana,
  password,
  hankaku_num,
  hankaku_num_2,
  time_valid,
  news_base_org_valid,
  alpha_num_2
}
