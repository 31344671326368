export default {
  appname: "co2",
  menu: {
    "user-name-label": "ユーザー：",
    dashboard: "ダッシュボード",
    dashboard2: "ダッシュボードTEST", // REMOVE LATER
    "activity-board": "活動履歴",
    "activity-input": "活動量入力",
    "setting": "各種設定",
    "setting-process": "活動・プロセス",
    "setting-organization": "組織情報",
    "setting-company": "企業情報",
    "setting-fiscal-year": "年度情報",
    "setting-calculate": "算定方法",
    "setting-user": "ユーザー登録",
    "setting-mst-retail-electricity-providers": "小売電気事業者マスタ",
    "setting-mst-retail-electricity-providers-emission": "小売電気事業者排出係数マスタ",
    "setting-master-maintenance": "マスタメンテナンス",
    "setting-mst-master-register-csv-export": "各種マスタcsv出力/取込",
    "setting-masterregistercsvexport": "各種マスタ登録/csv出力画面",
    "setting-mst-emissions-unit-value-master": "ユーザー用排出原単位マスタ",
    'setting-mst-transport-master': '輸送マスタ',
    "setting-news": "お知らせ情報",
    "setting-industry-templates-master": "業種別テンプレート情報",
    logout: "ログアウト",
  },
  common: {
    location: "拠点",
    affiliation: "所属",
    dept: "事業部",
    selectAll: "全選択",
    filter: "絞り込み",
    save: "保存",
    category: {
      "01": "カテゴリ1",
      "02": "カテゴリ2",
      "03": "カテゴリ3",
      "04": "カテゴリ4",
      "05": "カテゴリ5",
      "06": "カテゴリ6",
      "07": "カテゴリ7",
      "08": "カテゴリ8",
      "09": "カテゴリ9",
      "10": "カテゴリ10",
      "11": "カテゴリ11",
      "12": "カテゴリ12",
      "13": "カテゴリ13",
      "14": "カテゴリ14",
      "15": "カテゴリ15",
      other: "その他",
    },
    confirm: {
      warning: '注意',
      confirm: '確認',
      remove_confirm: '{name}を削除します。よろしいですか？',
      remove_unsaved_row: '空白行を削除します。よろしいですか？',
      unsaved_data: '入力した内容は保存されませんが、よろしいですか？<br>「はい」　：入力した内容を破棄して画面遷移します。<br>「いいえ」：{screenName}画面に戻ります。',
      remove_existed_electric_company_confirm: '登録されている排出係数があります。排出係数も削除されますがよろしいですか？'
    },
    form: {
      noData: '一致するものがありません。'
    },
    search: '検索'
  },
  activity: {
    templateImport: "テンプレート取込",
    tempSave: "保存",
    monthlyUpdate: "月次更新",
    targetMonth: "対象年月",
    divisionType: "カテゴリー",
    divisionTypeLocation: "拠点",
    divisionTypeDepartment: "事業部",
    tempTotalScore: "全社",
    selectScope: "スコープ",
    filter: {
      targetMonthF: "対象年月From",
      targetMonthT: "対象年月To",
      inputDivision: "対象区分",
      inputSelectScope: "対象スコープ",
    },
    input: {
      common: {
        activityName: "活動名",
        fuelType: "燃料種類",
        fuelUsage: "燃料使用量",
        energyType: "エネルギー種別",
        energyUsage: "エネルギー消費量",
        electricityUsage: "電力使用量",
        electricCompany: "電力会社",
        electricPlan: "電力プラン",
        hfcUsage: "HFCの使用量",
        weight: "重量",
        machineModel: "機種",
        purchaseAmount: "購入量",
        purchaseMoney: "購入金額",
        money: "金額",
        departmentName: "部門名",
        productName: "製品名",
      },
      capital: {
        capitalGoodsName: "資本財名",
        capitalDivision: "資本財",
      },
      transport: {
        maximumPayload: "最大積載量",
        cargoWeight: "貨物重量",
        transportDistance: "輸送距離",
        accumulationRate: "積載率",
        transportMode: "輸送モード",
      },
      trash: {
        disposalMethod: "処理方法",
        transportStage: "輸送段階",
      },
      business_trip: {},
      workCome: {
        traffic_class: "交通区分",
        traffic_allowance: "交通費支給額",
      },
      rease: {
        buildingUse: "建物用途",
      },
    },
    button: {
      search: "検索",
      clear: "検索解除",
      addInput: "入力欄",
      removeInput: "入力欄削除",
      add: "追加",
      update: "更新",
    },
    board: {
      unInput: "未入力",
    },
    category_1: {
      register_procurement: '調達品マスタ登録',
      procurement_id: '調達品ID',
      procurement_name: '調達品名',
      transport_activity: '輸送活動',
      confirm_create_procurment: 'マスタに登録されていない名称です。<br>マスタ登録を行いますか？'
    },
    import_transport: {
      search_criteria: '【検索条件】',
      no_route: '経路情報がありません。',
      headers: {
        activity_name: '活動名',
        supplier: '取引先',
        shipping_from: '発送元/発送先',
        memo: 'メモ',
      },
      load_ratio: {
        title: '積載率設定',
        route: '経路',
        load_ratio_setting: '積載率設定',
        description: 'トラック積載率を設定して下さい。',
        truck_load_ratio: 'トラック積載率'
      }
    }
  },
  // registRequest: {
  //   image: {
  //     fileBtn: "画像を追加",
  //     removeBtn: "表示中の画像を削除",
  //   },
  //   item: {
  //     currentBtn: "現在日時を設定",
  //     moneysuffix: "枚",
  //     summaryhint: "金額：[0]円",
  //   },
  //   tags: {
  //     addBtn: "タグを追加",
  //   },
  //   bundle: {
  //     addBtn: "同梱品を追加",
  //   },
  // },
  // printDocument: {
  //   criteria: {
  //     title: "印刷条件",
  //     category: "カテゴリ",
  //     additionalTitle: "追加条件",
  //     idF: "拾得物ID(From)",
  //     idT: "拾得物ID(To)",
  //     pickedDateF: "拾得日(From)",
  //     pickedDateT: "拾得日(To)",
  //     name: "名称",
  //     place: "拾得場所",
  //     storageLocation: "保管場所",
  //     storageTermF: "保管期限(From)",
  //     storageTermT: "保管期限(To)",
  //   },
  // },
  // item: {
  //   image: {
  //     title: "画像",
  //     image: "画像",
  //   },
  //   lost_property: {
  //     title: "物品情報",
  //     title_bundle: "内容物",
  //     management_title: "管理情報",
  //     item_id: "拾得物ID",
  //     item_id_suffix: "（自動採番）",
  //     picked_date: "拾得日",
  //     picked_time: "拾得時刻",
  //     acceptance_date: "受理日",
  //     acceptance_time: "受理時刻",
  //     picked_place: "拾得場所",
  //     item_category: "分類",
  //     item_category_label: "分類",
  //     item_sub_category: "中分類",
  //     item_sub_category_label: "中分類",
  //     item_class: "小分類",
  //     item_class_label: "小分類",
  //     is_valuables: "貴重品",
  //     item_valuables_flg_label: "貴重品",
  //     item_report_flg: "届出対象",
  //     item_report_flg_label: "届出対象",
  //     facility_right_claim: "施設権利",
  //     facility_right_claim_label: "施設権利",
  //     item_name: "品名",
  //     item_description: "詳細",
  //     storage_loc: "保管場所",
  //     storage_loc_label: "保管場所",
  //     storage_term: "保管期限",
  //     item_report_date: "警察届出日",
  //     property_remark: "備考",
  //     item_status: "状況",
  //     item_status_label: "状況",
  //     item_status_date: "状況更新日",
  //     item_staff_name: "対応者",
  //     print_no: "印刷番号",
  //     delete_flg: "削除フラグ",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   finder: {
  //     title: "拾得者情報",
  //     finder_name: "拾得者氏名",
  //     finder_type: "拾得者種別",
  //     finder_type_label: "拾得者種別",
  //     right_claim: "権利主張",
  //     right_claim_label: "権利主張",
  //     announcement_concent: "告知同意",
  //     announcement_concent_label: "告知同意",
  //     finder_tel: "拾得者電話番号",
  //     finder_zipcode: "拾得者郵便番号",
  //     finder_address: "拾得者住所",
  //     finder_remark: "拾得者備考",
  //   },
  //   owner: {
  //     title: "引渡情報",
  //     owner_name: "遺失者氏名",
  //     owner_tel: "遺失者電話番号",
  //     owner_zipcode: "遺失者郵便番号",
  //     owner_address: "遺失者住所",
  //     owner_remark: "遺失者備考",
  //     return_staff_name: "返却担当者",
  //     return_method: "返還方法",
  //     return_method_label: "返還方法",
  //     due_date: "返却予定日",
  //     returned_date: "返却完了日",
  //   },
  //   cash: {
  //     title: "金種情報",
  //     bill10000: "1万円札",
  //     bill5000: "5千円札",
  //     bill2000: "2千円札",
  //     bill1000: "1千円札",
  //     coin500: "500円硬貨",
  //     coin100: "100円硬貨",
  //     coin50: "50円硬貨",
  //     coin10: "10円硬貨",
  //     coin5: "5円硬貨",
  //     coin1: "1円硬貨",
  //     other: "その他有価証券",
  //   },
  //   keyword: {
  //     title: "検索用タグ",
  //     tagname: "タグ",
  //   },
  //   bundle: {
  //     title: "同梱情報",
  //     bundleid: "同梱品ID",
  //   },
  //   edit: {
  //     inputs: {
  //       current_date_btn: "現在日時を設定",
  //       add_image_btn: "画像を追加",
  //       add_keyword_btn: "タグを追加",
  //       add_bundle_btn: "同梱品を追加",
  //       money_suffix: "枚",
  //       other_suffix: "円",
  //       summary_hint: "金額：[0]円",
  //     },
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       title: "SCOPE3　絞り込み",
  //       category: "カテゴリ",
  //       additional_title: "追加条件",
  //       id: "ID",
  //       status: "状況",
  //       picked_date_f: "拾得日From",
  //       picked_date_t: "拾得日To",
  //       name: "名称",
  //       item_valuables_flg: "貴重品",
  //       item_report_flg: "届出対象",
  //       place: "拾得場所",
  //       storage_location: "保管場所",
  //       storage_term_f: "保管期限From",
  //       storage_term_t: "保管期限To",
  //       due_date_f: "返却予定日From",
  //       due_date_t: "返却予定日To",
  //       freeword: "フリーワード",
  //       output_file_type: "出力帳票",
  //       exclude_printed: "印刷済み",
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  // request: {
  //   image: {
  //     title: "参考画像",
  //     image: "参考画像",
  //   },
  //   request: {
  //     title: "探索対象",
  //     client_title: "依頼者情報",
  //     management_title: "管理情報",
  //     return_title: "返還情報",
  //     request_id: "探索依頼ID",
  //     lost_date: "紛失日",
  //     lost_feature: "詳細",
  //     lost_place: "紛失場所",
  //     requester_name: "依頼者氏名",
  //     requester_tel: "電話番号",
  //     requester_zipcode: "郵便番号",
  //     requester_address: "住所",
  //     requester_remark: "備考",
  //     found_company_id: "発見企業ID",
  //     found_office_id: "発見事業所ID",
  //     found_item_id: "発見拾得物ID",
  //     request_status: "状況",
  //     request_status_label: "状況",
  //     request_status_date: "状況更新日",
  //     request_staff_name: "対応者",
  //     request_contact_type: "連絡要否",
  //     request_contact_type_label: "連絡要否",
  //     request_contact_term: "連絡期限",
  //     last_updated: "最終更新日",
  //     last_update_user_name: "最終更新者",
  //   },
  //   list: {
  //     current: "表示条件：[0]",
  //     criteria_btn: "表示条件",
  //     criteria: {
  //       created_f: "登録日From",
  //       created_t: "登録日To",
  //       title: "表示条件",
  //       freeword: "フリーワード",
  //       id: "ID",
  //       status: "状況",
  //       lost_date_f: "紛失日From",
  //       lost_date_t: "紛失日To",
  //       request_contact_term_f: "連絡期限From",
  //       request_contact_term_t: "連絡期限To",
  //     },
  //   },
  //   detail: { title: "詳細" },
  // },
  maintenance: {
    org: {
      "base_name": "拠点名称",
      "start_month": "適用開始年月",
      "end_month": "適用終了年月",
      "electric_company_no": "電気事業者登録番号",
      "work_style_city_code": "勤務形態別都市区分コード",
      "display_order": "表示順",
      "organization_id": "組織コード",
      "parrent_id": "親組織",
      "organization_name": "組織名称",
      "affiliation_base": "所属拠点",
      "display_order": "表示順",
    },
    company: {
      title: "企業設定",
      detail_title: "企業情報",
      company_id: "企業ID",
      company_name: "企業名",
      company_contact_name: "企業担当者名",
      company_contact_tel: "企業担当者電話番号",
      company_contact_fax: "企業担当者FAX番号",
      company_contact_email: "企業担当者メールアドレス",
      updated: "最終更新日",
      company_name_label: "企業名称",
      company_name_label_kana: "企業名称カナ",
      zip_code: "郵便番号",
      address_city: "所在地県市町村",
      address_house_number: "所在地番号",
      specific_emitter_code: "特定排出者コード",
      capital_formation_code: "資本形成部門コード",
      organization1_name: "組織階層１名称",
      organization2_name: "組織階層２名称",
      organization3_name: "組織階層３名称",
      organization4_name: "組織階層４名称",
      franchise_flag_name: "フランチャイズ展開",
      business_year_start_at: "年度の開始月"
    },
    // company_settings: {
    //   detail_title: "操作設定",
    //   label: {
    //     default_storage_selected: "保管場所初期値",
    //     default_user_input: "対応者初期値",
    //     default_facility_right_claim: "施設権利初期値",
    //     update_place_hisstory: "拾得場所自動更新",
    //     default_finder_type: "拾得者種別初期値",
    //     default_item_complete_status: "拾得物完了登録初期値",
    //     finder_input_always: "拾得者情報入力",
    //     item_insert_transition: "拾得物登録時移動先",
    //     item_update_transition: "拾得物更新時移動先",
    //     request_insert_transition: "探索依頼登録時移動先",
    //     request_update_transition: "探索依頼更新時移動先",
    //   },
    // },
    // office: {
    //   title: "事業所設定",
    //   office_list_title: "事業所一覧",
    //   place_list_title: "拾得場所一覧",
    //   sl_list_title: "保管場所一覧",
    //   detail_title: "事業所情報",
    //   doc_info_title: "届出書類印字設定",
    //   doc_informant_title: "届出者情報",
    //   office_id: "事業所ID",
    //   office_name: "事業所名",
    //   send_to: "届出先",
    //   informant_name: "届出者名",
    //   informant_address: "届出者住所",
    //   informant_tel: "届出者電話番号",
    //   informant_other_contact: "届出者その他連絡先",
    //   rep_post: "代表者役職",
    //   rep_name: "代表者名",
    //   office_storage_loc_name: "保管場所名称",
    //   office_storage_loc_address: "保管場所所在地",
    //   office_storage_loc_tel: "保管場所電話番号",
    //   office_storage_loc_other_contact: "保管場所その他連絡先",
    //   picked_placeType: "拾得場所区分",
    //   cities_code: "市区町村コード",
    //   office_picked_place_name: "拾得場所施設名",
    //   office_picked_place_address: "拾得場所所在地",
    //   default_finder: "拾得者初期値",
    //   informant_picked_place_flg: "届出時拾得場所",
    //   updated: "最終更新日",
    //   doc_info: "帳票設定",
    //   place_count: "拾得場所 : 保管場所",
    //   modify_buttons: "",
    // },
    user: {
      title: "利用者一覧",
      detail_title: "利用者情報",
      office_id: "事業所ID",
      office_name: "事業所",
      user_id: "利用者ID",
      user_name: "利用者名",
      role_id_list: "権限",
      updated: "最終更新日",
      modify_buttons: "",
      user_password: "パスワード",
      confirm_password: "パスワード(確認)",
    },
    edit: {
      password: {
        title: "パスワード変更",
        old_password: "現在のパスワード",
        new_password: "新しいパスワード",
        confirmed_password: "新しいパスワード(確認)",
      },
    },
  },
  // registReceipt: {
  //   search: {
  //     title: "更新対象",
  //     sequenceNo: "印刷整理番号",
  //   },
  //   edit: {
  //     title: "届出情報",
  //     staff_name: "担当者名",
  //     receipt_no: "受理番号",
  //     receipt_date: "受理日",
  //     receipt_time: "受理時刻",
  //   },
  //   list: {
  //     title: "対象一覧",
  //   },
  // },
  item: {
    list: {
      criteria: {
        title: "SCOPE3　絞り込み",
      },
    },
  },
  message: {
    noOptionsText: "該当する{0}が存在しません。",
    noChildrenText: "該当する{0}が存在しません。",
    removeInputListItem: "入力欄を取り消します。よろしいですか？",
  },
  api: {
    register_success: '登録されました。',
    update_success: '更新されました。',
    remove_success: '消除されました。',
    error: '何らかのエラーが発生しました。後ほどもう一度実行してください。',
    duplicated_error: '{name}はすでに存在しています。他の{name}を入力してください。'
  },
  scope: {
    1: 'SCOPE1 温室効果ガス直接排出',
    2: 'SCOPE2 電気・熱の使用に伴う間接排出',
    3: 'SCOPE3 その他の間接排出'
  },
  'fiscal_year_info': {
    headers: {
      'fiscal_year': '年度',
      'emission_target_value': '排出目標',
      'personnel': '従業員数',
      'business_days': '営業日数',
      'category6_calculation_flag': 'カテゴリ６出張の算定方法'
    },
    remove_row_message: '年度情報を削除します。よろしいですか？',
    change_calculation_error: '既に変更前の算定方法で活動量が入力されています。'
  },
  buttons: {
    add: '追加',
    edit: '修正',
    update: '更新',
    add_row: '行追加',
    regenerate_password: 'パスワード再発行',
    save: '保存',
    search: '検索',
    csvExport: 'csv出力',
    csvImport: 'csv取込',
    detail: '詳細',
    import_transport_activty: '輸送活動取込',
    import: '取込',
    setting: '設定',
    back: '戻る'
  },
  user: {
    remove_user: 'ユーザー（{userId}）を削除します。よろしいですか。',
    regenerate_password_confirm: 'ユーザー（{userId}）のパスワードを再発行します。よろしいですか。',
    register_success: '以下のユーザー登録が完了しました。',
    regenerate_password_success: '以下のユーザーのパスワードを再発行しました。',
    form: {
      userId: 'ユーザーID',
      password: 'パスワード',
      temp_password: '仮パスワード'
    },
    loged_in: "ログイン中のユーザーは削除できません。",
  },
  login: {
    not_authorized_error: 'IDまたはパスワードが正しくありません。',
    input_new_password: '新しいパスワードを入力してください。',
    setting: '設定'
  },
  master_maintenance: {
    explainText: 'マスタの更新を行う場合はcsvファイルを出力し、csvファイルの修正を行い取込を行ってください。',
    explainText1: ' ※csvファイルの内容で全件更新されます。',
    selectFirstValue: '対象マスタを選択してください',
    confirmUploadFIle: 'を取り込みます。よろしいですか？',
    retail_electricity_providers: {
      headers: {
        electricCompanyNo: '電気事業者登録番号',
        electricCompanyName: '電気事業者名',
        electricCompany: '電気事業者',
        displayOrder: '表示順',
        memo: 'メモ',
        displayFlag: '非表示',
        calculationMethod: '算定方法',
        supplyMenu: '供給メニュー',
        emissionFactor: '排出係数'
      },
      errors: {
        checkSupplyMenu: "同一電気事業者で供給メニュー無しと有りは混在できません。",
        checkDuplicate: '同一の電気事業者/算定方法/供給メニューが存在しています。',
      },

      duplicated_error: '電気事業者登録番号が重複しています。'

    },
    fileTypeCheck: 'csvファイル形式を選択してください。',
    fileNameCheck: 'ファイル名は間違っています。',
    dataFileCheck: 'csvファイルの内容がありません。ファイル内容を確認して下さい。',
    firtCheckData: '空データを取り込みます。マスタは全件クリアされますがよろしいですか？',
    checkNumberHeaders: 'csvの内容がマスタと一致していません。csvデータの項目数を確認して下さい。対象データ：1行目',
    checkNumberRecords: 'csvの内容がマスタと一致していません。csvデータの項目数を確認して下さい。対象データ：{countRows}行目',
    checkHeaderName: 'csvの内容がマスタと一致していません。csvのヘッダ部を確認して下さい。',
    checkFirts: 'ファイル取込中。。。',
    add_description: '検索時は行追加はできません。行追加を行う場合は、検索を解除して下さい。',
    emission_unit_value_master: {
      headers: {
        usage: '用途',
        emissionintensity: '排出原単位',
        unit: '単位',
        basis: '根拠',
        category: '使用するカテゴリ',
      },
    },
    transport_master: {
      headers: {
        transport_name: '輸送活動名',
        transport_id: '輸送活動ID',
        procurement_shipping: '調達/出荷',
        suppliers: '取引先',
        location: '発送先/発送元（場所）',
        memo: 'メモ',
        route: '経路'
      },
      dialog: {
        transport_activity: '輸送活動',
        route_detail: '経路詳細',
        transport_emission: '輸送に伴う排出量',
        route_name: '経路名',
        input_method: '入力方法',
        calculation_method: '算定方法',
        fuel_type: '燃料の種類',
        fuel_consumption: '燃料使用量',
        transport_agency: '輸送機関',
        transport_class: '輸送の区分',
        transport_distance: '輸送距離',
        cargo_volume: '貨物重量',
        car_business_private: '営業用・自家用の別',
        understand_fuel: '燃料の把握'
      }
    },
    news_providers: {
      headers: {
        news_start_date: '開始期間',
        news_end_date: '終了期間',
        news_content: '内容',
        delivery_base: '対象拠点',
        delivery_organization: '対象所属',
        division: '区分',
      },
      news_text: "お知らせ情報",
    }
  },
  news: {
    news: 'お知らせ',
    emergency: '緊急',
    important: '重要',
    normal: '通常',
    counter: '個'
  },
  user_registration: {
    user_id: "ユーザーID",
    user_name_kanji: "ユーザー氏名",
    user_name_kana: "ユーザー氏名カナ",
    authority: "権限",
    organization: "組織",
    email: "メールアドレス",
    update_self: '自分自身の権限を変更した場合、自動でログアウトされます。<br>再度ログインして下さい。'
  },
  industry_templates_master: {
    search_condition: {
      industry: "業種"
    },
    category_dialog: {
      template: "テンプレート",
      detail: "詳細",
      category: "カテゴリ",
    },
    headers: {
      scope3_category: "SCOPE3カテゴリ",
      activity_name: "活動名",
      calculation_method: "算定方法",
      download_target: "取込対象",
      update_name: "更新者",
    },
    change_industry: {
      confirm: 'すでに{industryName}のテンプレートが存在していますが、作業を続けますか？',
    },
    not_editable: "適用終了している拠点/所属です。テンプレートの編集はできません。",
  },
  unauthorized: {
    description: '権限がありません。'
  },
  dashbord: {
    by_scope: {
      titles: {
        total_score: '全体CO2排出量累計値',
        objective_score: '全体CO2排出量目標値',
        upstream: '上流',
        downstream: '下流',
        own_company: '自社',
      },
      descriptions: {
        scope_one: '自社での燃料の使用や工業プロセスによる直接排出',
        scope_two: '自社が購入した電気・熱の排出に伴う間接排出',
      }
    },
    by_bar_chart: {
      titles: {
        rate_per_month: '全体CO2排出量の推移',
        rate_by_fuel: '燃料別排出量推移',
        rate_by_base: '拠点別排出量推移',
      },
      checkbox_label: '推移予測を表示する',
    },
    by_pie_chart: {
      titles: {
        chart_by_scope: 'SCOPE別内訳',
        scope_three_all_org: '全社SCOPE3内訳',
        by_dept: '組織内訳',
        by_dept_scope: '組織別SCOPE内訳',
        by_loc: '拠点内訳',
        by_loc_scope: '拠点別SCOPE内訳',
        by_dept_table: '組織別（最上位）CO2排出量',
        by_loc_table: '拠点別CO2排出量'
      },
      checkbox_label: '推移予測を表示する',
    },
    emission_amount: 'CO2排出量',
    year: '年度',
    selected_year: '表示年度',
    start_month: '表示開始月',
    end_month: '表示終了月',
    month: '月',
  }
}
