import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0020c23c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _040f2ea6 = () => interopDefault(import('../pages/dashboard2.vue' /* webpackChunkName: "pages/dashboard2" */))
const _1c05c87c = () => interopDefault(import('../pages/unauthorized.vue' /* webpackChunkName: "pages/unauthorized" */))
const _757c8a6e = () => interopDefault(import('../pages/activity/board.vue' /* webpackChunkName: "pages/activity/board" */))
const _3af7e152 = () => interopDefault(import('../pages/activity/input.vue' /* webpackChunkName: "pages/activity/input" */))
const _91544ee2 = () => interopDefault(import('../pages/setting/calculate.vue' /* webpackChunkName: "pages/setting/calculate" */))
const _d9744334 = () => interopDefault(import('../pages/setting/company.vue' /* webpackChunkName: "pages/setting/company" */))
const _79a7d8d5 = () => interopDefault(import('../pages/setting/fiscal-year.vue' /* webpackChunkName: "pages/setting/fiscal-year" */))
const _665a51a4 = () => interopDefault(import('../pages/setting/industry-templates-master.vue' /* webpackChunkName: "pages/setting/industry-templates-master" */))
const _e49d408c = () => interopDefault(import('../pages/setting/news.vue' /* webpackChunkName: "pages/setting/news" */))
const _536d531a = () => interopDefault(import('../pages/setting/organization.vue' /* webpackChunkName: "pages/setting/organization" */))
const _84b51150 = () => interopDefault(import('../pages/setting/process.vue' /* webpackChunkName: "pages/setting/process" */))
const _2aab84b2 = () => interopDefault(import('../pages/setting/user.vue' /* webpackChunkName: "pages/setting/user" */))
const _0d93bfe4 = () => interopDefault(import('../pages/setting/mst/emissions-unit-value-master.vue' /* webpackChunkName: "pages/setting/mst/emissions-unit-value-master" */))
const _397b5622 = () => interopDefault(import('../pages/setting/mst/master-register-csv-export.vue' /* webpackChunkName: "pages/setting/mst/master-register-csv-export" */))
const _d223bd78 = () => interopDefault(import('../pages/setting/mst/retail-electricity-providers.vue' /* webpackChunkName: "pages/setting/mst/retail-electricity-providers" */))
const _237c4420 = () => interopDefault(import('../pages/setting/mst/retail-electricity-providers-emission.vue' /* webpackChunkName: "pages/setting/mst/retail-electricity-providers-emission" */))
const _6a4a02ce = () => interopDefault(import('../pages/setting/mst/transport-master.vue' /* webpackChunkName: "pages/setting/mst/transport-master" */))
const _38edb97a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _0020c23c,
    name: "dashboard"
  }, {
    path: "/dashboard2",
    component: _040f2ea6,
    name: "dashboard2"
  }, {
    path: "/unauthorized",
    component: _1c05c87c,
    name: "unauthorized"
  }, {
    path: "/activity/board",
    component: _757c8a6e,
    name: "activity-board"
  }, {
    path: "/activity/input",
    component: _3af7e152,
    name: "activity-input"
  }, {
    path: "/setting/calculate",
    component: _91544ee2,
    name: "setting-calculate"
  }, {
    path: "/setting/company",
    component: _d9744334,
    name: "setting-company"
  }, {
    path: "/setting/fiscal-year",
    component: _79a7d8d5,
    name: "setting-fiscal-year"
  }, {
    path: "/setting/industry-templates-master",
    component: _665a51a4,
    name: "setting-industry-templates-master"
  }, {
    path: "/setting/news",
    component: _e49d408c,
    name: "setting-news"
  }, {
    path: "/setting/organization",
    component: _536d531a,
    name: "setting-organization"
  }, {
    path: "/setting/process",
    component: _84b51150,
    name: "setting-process"
  }, {
    path: "/setting/user",
    component: _2aab84b2,
    name: "setting-user"
  }, {
    path: "/setting/mst/emissions-unit-value-master",
    component: _0d93bfe4,
    name: "setting-mst-emissions-unit-value-master"
  }, {
    path: "/setting/mst/master-register-csv-export",
    component: _397b5622,
    name: "setting-mst-master-register-csv-export"
  }, {
    path: "/setting/mst/retail-electricity-providers",
    component: _d223bd78,
    name: "setting-mst-retail-electricity-providers"
  }, {
    path: "/setting/mst/retail-electricity-providers-emission",
    component: _237c4420,
    name: "setting-mst-retail-electricity-providers-emission"
  }, {
    path: "/setting/mst/transport-master",
    component: _6a4a02ce,
    name: "setting-mst-transport-master"
  }, {
    path: "/",
    component: _38edb97a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
