import ActivityService from "./services/ActivityService";
import OrganizationService from "./services/OrganizationService";
import CompanyService from "./services/CompanyService";
import DashboardService from "./services/DashboardService";
import UserSettingService from "./services/UserSettingService";
import SignService from "./services/SignService";
import FiscalYearService from "./services/FiscalYearService";
import MasterCSVExportService from "./services/MasterCSVExportService";
import Emissionsunitvaluemaster from "./services/Emissionsunitvaluemaster";
import RetailElectricityProvidersEmissionService from "./services/RetailElectricityProvidersEmissionService";
import RetailElectricityProvidersService from "./services/RetailElectricityProvidersService";
import TransportMaster from "./services/TransportMaster";
import NewsProvidersService from "./services/NewsProvidersService";
import TemplateActivityService from "./services/TemplateActivityService";
import CommonService from "./services/CommonService";

export default function ({ $axios }, inject) {
  const api = {
    activity: new ActivityService($axios),
    company: new CompanyService($axios),
    user: new UserSettingService($axios),
    signIn: new SignService($axios),
    organization: new OrganizationService($axios),
    dashboard: new DashboardService($axios),
    fiscalYear: new FiscalYearService($axios),
    masterregistercsvexport: new MasterCSVExportService($axios),
    emissionsunitvaluemaster: new Emissionsunitvaluemaster($axios),
    retailElectricityProvidersEmission: new RetailElectricityProvidersEmissionService($axios),
    retailElectricityProviders: new RetailElectricityProvidersService($axios),
    transportMaster: new TransportMaster($axios),
    newsProviders: new NewsProvidersService($axios),
    templateActivityService: new TemplateActivityService($axios),
    common: new CommonService($axios),
  }
  inject('api', api)
}