export default class DashboardService {
  constructor(axios) {
    this.axios = axios
  }

  async getDashboardInit(params) {
    return await this.axios.$get('/api/v2/dashboard/dashboard-init', { params: params });
  }

  async getNewsInit(params) {
    return await this.axios.$get('/news/dashboard/news', { params: params });
  }

  async getActivityByScope(params) {
    return await this.axios.$get('/dashboard/scopes', { params: params });
  }

  async getActivityByOrganization(params) {
    return await this.axios.$get('/dashboard/organizations', { params: params });
  }

  async getActivityByBase(params) {
    return await this.axios.$get('/dashboard/bases', { params: params });
  }

  async getNews(params) {
    return await this.axios.$get('/dashboard/news', { params: params });
  }
  // async getActivityByScopeOrg(params) {
  //   return await this.axios.$get('/dashboard/scope-orgs', { params: params });
  // }

  async getActivityByScopeBase(params) {
    return await this.axios.$get('/dashboard/scope-bases', { params: params });
  }

  async getActivityByScopeMonth(params) {
    return await this.axios.$get('/api/v2/dashboard/bar-chart-by-date', { params: params });
  }

  async getActivityByScopeCategory(params) {
    return await this.axios.$get('/dashboard/scope-categories', { params: params });
  }

  async getTotalActivityByYear(params) {
    return await this.axios.$get('/api/v2/dashboard/total-by-year', { params: params });
  }

  async getAllHelp() {
    return await this.axios.$get('/dashboard/help');
  }

  async getFiscalEmissionVolume(params) {
    return await this.axios.$get('/api/v2/dashboard/fiscal-emission-volume', { params: params });
  }

  async getOrgWithAllScopes(params) {
    return await this.axios.$get('/api/v2/dashboard/org/all-scopes', { params: params });
  }

  async getBaseWithAllScopes(params) {
    return await this.axios.$get('/dashboard/base/all-scopes', { params: params });
  }

  async getAllTags() {
    return await this.axios.$get('/dashboard/tag-categories');
  }

  async getEmissionVolTag(params) {
    return await this.axios.$get('/dashboard/tag-emission-vol', { params: params });
  }

  async getTagByGroup(params) {
    return await this.axios.$get('/dashboard/tag-by-group', { params: params });
  }

  async getEmissionVolTagByScope(params) {
    return await this.axios.$get('/dashboard/tag-emission-vol-by_scope', { params: params });
  }

  getFiscalYear() {
    return this.axios.$get('/api/v2/dashboard/fiscalyears')
  }

}